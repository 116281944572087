import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'
import urlParser from 'url-parse'
import isEmpty from 'lodash/isEmpty'
import { request } from 'gaxios'
import { StrapiAuthResponse } from 'vendors/Strapi/interfaces'
import { useAppVars, useAppCookies } from 'hooks'
import { Contexts } from 'libs'
// @Todo use individual imports instead - look into a linting rule for this from Material UI, specifically
import { Button, Container, FormControlLabel, Grid, TextareaAutosize, TextField } from '@material-ui/core'

const OAuth2Redirect: FC = () => {
  const { user, setUser, } = React.useContext(Contexts.Auth)
  // const [user, setUser] = React.useState<StrapiUser>() // @Todo set user in shared context after auth
  const { cookies, setCookie, defaultOptions, } = useAppCookies()
  const { search, } = useLocation()
  const { query, } = urlParser(search, true)
  // get app variable loader
  const load = useAppVars()

  const { access_token: accessToken, id_token: idToken, } = query

  const { AUTH_URL, } = load()

  const doAuth = React.useCallback(async () => {
    // @Todo setup Error Boundary at the vendor level
    if(isEmpty(accessToken)) throw new Error('ID Token is required to complete the OAuth request')

    setCookie('providerAccessToken', accessToken, defaultOptions)
    setCookie('providerIdToken', idToken, defaultOptions)

    console.debug({ AUTH_URL, accessToken, })

    // @Todo implement try / catch logic to handle errors here
    // @Todo look into this for some ideas: https://github.com/strapi/strapi/issues/3601#issuecomment-510810027
    const { data, } = await request<StrapiAuthResponse>({
      url: `${AUTH_URL}/auth/google/callback`,
      params: {
        access_token: accessToken,
      },
    })

    console.debug({ data, })

    setCookie('userId', data?.user?.id, defaultOptions)
    // @Todo verify that this IS the Strapi JWT
    setCookie('jwt', data.jwt, defaultOptions)
    if(data?.user && setUser) {
      setUser(data.user)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, idToken])

  React.useEffect(() => {
    if(accessToken) {
      void doAuth()
    }
  }, [accessToken, doAuth])

  // @Todo handle re-direct in a useEffect
  const { jwt, userId, providerAccessToken, } = cookies

  console.debug({ query, user, jwt, providerAccessToken, userId, })

  return (
    <Container maxWidth="sm">
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        style={{ minHeight: '100vh', }}>
        {isEmpty(user) ? <>Authenticating...</> : (
          <>
            <Grid item>
              <FormControlLabel
              label="Email"
              labelPlacement="top"
              control={
                <TextField
                  placeholder="Your email address"
                  value={user?.email} />
              } />
            </Grid>
            <Grid item>
              <FormControlLabel
              label="JWT Token"
              labelPlacement="top"
              control={
                <TextareaAutosize
                  placeholder="User's JWT Token"
                  rowsMin={5}
                  rowsMax={10}
                  value={(jwt as string) ?? ''} />
              } />
            </Grid>
            <Grid item>
              <Button href="/welcome">Done</Button>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  )
}

export default OAuth2Redirect

