import { ErrorWithGaxiosResponse } from 'shared/interfaces'

export * as Contexts from './contexts'

export function isValidGaxiosResponseError(
  err: ErrorWithGaxiosResponse | Record<string, unknown> | undefined
): err is ErrorWithGaxiosResponse {
  if (err && !!err?.response) {
    return (err?.response as ErrorWithGaxiosResponse) !== undefined
  }
  return false
}
