import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { CenteredGridLayout as Layout } from '../layouts'
import { ActionsBar, FocusBox } from '../components'

type ErrorCallerRetryCallback = () => void | unknown

interface ErrorBoundaryState {
  hasError?: boolean
  retryCallback?: ErrorCallerRetryCallback
  defaultSupportInbox?: string
}

interface ErrorMetaData {
  retryCallback?: ErrorCallerRetryCallback
}

interface RichError extends Error {
  errors?: Record<string, unknown>
  metadata?: ErrorMetaData
  [key: string]: Record<string, unknown> | unknown
}

function isValidRichError(e: RichError | unknown) {
  return (e as RichError).message !== undefined
}

function isValidMetadata(d: ErrorMetaData | undefined | unknown) {
  if(!d) return false
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Object.keys(d as any).length > 0
}

function logErrorToService({
  message, name, errors, metadata, ...rest
}: RichError, errorInfo?: Record<string, unknown> | unknown) {
  if(typeof metadata?.retryCallback === 'function') {
    const { retryCallback, ...validMetaData } = metadata
    console.warn('Found retry function in metadata')
    console.warn('Will log error to semantic logger service >>', {
      message, name, errors, validMetaData, errorInfo,
    })
  }
}

export default class ErrorBoundary extends React.Component<Record<string, unknown>, ErrorBoundaryState> {
  constructor(props: Record<string, unknown>) {
    super(props)
    this.state = {
      hasError: false,
      defaultSupportInbox: 'webmaster@themorningcatalyst.com',
    }
  }

  static getDerivedStateFromError({
    message, name, errors, metadata, ...rest
  }: RichError): ErrorBoundaryState {
    const retryCallback = metadata?.retryCallback
    /**
     * @Todo update logic to utilize retry callback if available
     */
    // Update state so the next render will show the fallback UI.
    return { hasError: true, retryCallback, }
  }

  componentDidCatch(
    error: Error | RichError,
    errorInfo: Record<string, unknown> | unknown | undefined
    ): void {
    if(isValidRichError(error)) {
      const richError = error as RichError
      const metadata = richError?.metadata
      if(isValidMetadata(metadata)) {
        logErrorToService(richError, errorInfo)
      }
    }
  }

  onReload(): void {
    global.window.location.reload()
  }

  helpURL = (): string => {
    const { defaultSupportInbox, } = this.state
    const {
      supportEmail = defaultSupportInbox,
      subjectLine = 'Something broke on the website',
    } = this.props

    return `mailto:${supportEmail as string}?subject=${encodeURI(subjectLine as string)}`
  }

  render(): React.ReactNode {
    const { hasError, } = this.state
    const { handles, children, } = this.props
    console.debug({ handles, hasError, })

    if (hasError) {
      return (
        <Layout>
          <FocusBox>
            <Grid item>
              <Typography variant="h3">
                Oops...
              </Typography>
              <Typography variant="subtitle1">
                Sum'in broke
              </Typography>
              <ActionsBar item alignItems="flex-start">
                <Button onClick={this.onReload.bind(this)} disabled>Try again?</Button>
                <Button href={this.helpURL()}>Help</Button>
              </ActionsBar>
            </Grid>
          </FocusBox>
        </Layout>
      )
    }

    return children
  }
}

