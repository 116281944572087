import React from 'react'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import * as Yup from 'yup'

import 'react-phone-input-2/lib/material.css'

import Input, { ErrorCheckFunction } from 'shared/components/Input'
import { CenteredGridLayout } from 'shared/layouts'
import { phoneNumberSchema, emailSchema, passwordSchema, isValidationError } from '../libs/validators'
import { isValidGaxiosResponseError } from 'libs'
import { StrapiUserRegistration, ErrorMessage } from '../interfaces'
import { useRegistration } from '../hooks'
import { isValidStrapiErrorResponse } from '../libs'
import { CellPhoneNumberInput } from 'shared/components'
import { AuthContext } from 'shared/components/AuthProvider'

const formSchema = Yup.object().shape({
  username: phoneNumberSchema,
  name: Yup.string().required(),
  email: emailSchema,
  password: passwordSchema,
})

export default function JoinView(): JSX.Element {
  const [form, setForm] = React.useState<StrapiUserRegistration>({
    name: '',
    email: '',
    password: '',
  })
  const [, setRequestErrors] = React.useState<ErrorMessage[] | null | undefined>()
  const [, setErrors] = React.useState<undefined | null | Yup.ValidationError>()
  const { registerUser, } = useRegistration()
  const history = useHistory()
  const { isLoading, user, } = React.useContext(AuthContext)
  /**
   * Memoize form data to prevent unnecessary rerenders
   */
  const formData = React.useMemo(() => form, [form])

  const handleInputChange = ({ target, }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, } = target
    setForm({
      ...form,
      [name]: value,
    })
  }

  /**
   * Memoized version of validation handler as best practice
   * (will be useful if we end up having useEffect patterns here)
   */
  const validateForm = React.useCallback(async (): Promise<StrapiUserRegistration | undefined> => {
    try {
      await formSchema.validate(formData, { abortEarly: false, })
      setErrors(null)
      return formData
    } catch(err: unknown | Yup.ValidationError) {
      if(isValidationError(err)) {
        console.warn({ err, })
        setErrors(err)
      }
    }
  }, [formData])

  const hasError: ErrorCheckFunction = async ev => {
    const { target, } = ev
    const schema = formSchema.fields[target.name] as Yup.AnySchema
    if(schema) {
      const result = await schema.isValid(ev.target.value)
      if(!result) {
        try {
          await schema.validate(ev.target.value)
        } catch(err) {
          return err as Yup.ValidationError
        }
      }
      return !result
    }
    return true
  }

  /**
   * Memoized version of form handler (optimization, best practice for future useEffect patterns)
   */
  const processForm = React.useCallback(async () => {
    const userInfo = await validateForm()
    if(userInfo) {
      try {
        setRequestErrors(null)
        const response = await registerUser(userInfo)
        if(response.jwt) history.push('/secure/member/profile')
      } catch (err) {
        if(isValidGaxiosResponseError(err)) {
          if(isValidStrapiErrorResponse(err.response?.data)) {
            setRequestErrors(err.response?.data.message[0].messages)
          }
        }
      }
    }
  }, [validateForm, registerUser, history])

  React.useEffect(() => {
    if(!isLoading && !!user) {
      history.replace('/secure')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CenteredGridLayout>
      <Typography variant="h3">Your awesome rise experience starts here</Typography>
      <form noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CellPhoneNumberInput
              name="username"
              form={form}
              setState={setForm} />
          </Grid>

          <Grid item xs={12}>
            <Input
              label="Email"
              name="email"
              variant="outlined"
              value={form.email}
              placeholder="Your email address"
              validationSchema={emailSchema}
              autoComplete="email"
              hasError={hasError}
              onChange={handleInputChange} />
          </Grid>

          <Grid item xs={12} md={6}>
            <Input
              label="Name"
              name="name"
              variant="outlined"
              autoComplete="name"
              value={form.name}
              placeholder="Your full name"
              onChange={handleInputChange} />
          </Grid>

          <Grid item xs={12} md={6}>
            {/* Credit: html5pattern.com/Passwords */}
            <Input
              type="password"
              label="Password"
              name="password"
              variant="outlined"
              value={form.password}
              placeholder="Your new password"
              autoComplete="new-password"
              validationSchema={passwordSchema}
              onChange={handleInputChange} />
          </Grid>

          <Grid item xs={12}>
            <Button
              tabIndex={0}
              onClick={processForm}
              disabled={isLoading}>Continue</Button>

            <Button href="/member/login">Login</Button>
          </Grid>
        </Grid>

      </form>
    </CenteredGridLayout>
  )
}
