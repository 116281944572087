import React from 'react'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import { CenteredGridLayout } from 'shared/layouts'

// @Todo fail fast in Secure components if SecureAccessProvider is not detected

export default function Profile(): JSX.Element {
  // @Todo fail fast in Profile if no Auth context
  return (
    <CenteredGridLayout>
      <Grid container>
        <Grid item xs={12}>
          <h1>Profile goes here</h1>
          <Divider />
        </Grid>
      </Grid>
    </CenteredGridLayout>
  )
}
