/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React from 'react'
import { useCookies } from 'react-cookie'
import { AppCookieOptions } from '../environments/interfaces'
import useAppVars from './useAppVars'

export interface AppCookiesResources {
  cookies: Record<string, unknown>
  setCookie: (name: string, value: unknown, options?: Record<string, unknown>) => void
  removeCookie: (name: string, options?: Record<string, unknown>) => void
  defaultOptions: Partial<AppCookieOptions>
  attrs: string[]
  features: Record<string, string>
}

const useAppCookies = (): AppCookiesResources => {
  const load = useAppVars()
  const { COOKIE_NAME, COOKIE_OPTIONS, } = load()
  const [cookies, setCookie, removeCookie] = useCookies([COOKIE_NAME])

  const defaultOptions = React.useMemo<AppCookieOptions>(() => COOKIE_OPTIONS, [COOKIE_OPTIONS])

  return {
    cookies,
    setCookie,
    removeCookie,
    defaultOptions,
    attrs: [
      'email',
      'username',
      'blocked',
      'confirmed',
      'accessToken',
      'twilioVerificationToken'
    ],
    // @Todo Compose app features for "Rise"
    features: {
      'feat:v1': 'Version 1',
      'feat:v1:developer': 'Developer',
      'feat:v1:admin': 'Admin',
      'feat:v1:profile': 'Profile',
    },
  }
}

export default useAppCookies
