import { FC } from 'react'

const NotFound: FC = () => (
  <div>
    <h5>404</h5>
    <h1>There's nothing here from Google...</h1>
  </div>
)

export default NotFound
