import React, { Dispatch, SetStateAction } from 'react'
import { SetUserStateAction, UserInfoOrNothing } from 'shared/interfaces'

export interface AuthContextInterface {
  isLoading: boolean,
  user?: UserInfoOrNothing,
  setIsLoading: Dispatch<SetStateAction<boolean>> | undefined
  setUser: Dispatch<SetUserStateAction> | undefined
}

export const Auth = React.createContext<AuthContextInterface>({
  isLoading: false,
  setUser: undefined,
  setIsLoading: undefined,
})
