import React from 'react'
import { request } from 'gaxios'
import { StrapiAuthResponse, StrapiUserRegistration } from '../interfaces'
import { Contexts } from 'libs'
import { useAppVars, useAppCookies } from 'hooks'

type StrapiRegistrationHandler = (formData: StrapiUserRegistration) => Promise<StrapiAuthResponse>
type StrapiRegistrationHook = {
  registerUser: StrapiRegistrationHandler,
}

export default function useRegistration(): StrapiRegistrationHook {
  const load = useAppVars()
  const { setCookie, defaultOptions, } = useAppCookies()
  const { setUser, } = React.useContext(Contexts.Auth)
  const { CMS_URL, } = load()

  const registerUser = React.useCallback(async (
    formData: StrapiUserRegistration
  ) => {
    // @Todo user is getting created but error code is being returned
    const { data, } = await request<StrapiAuthResponse>({
      url: `${CMS_URL}/auth/local/register`,
      method: 'POST',
      headers: {
        accept: 'application/json',
      },
      data: formData,
    })
    setCookie('jwt', data.jwt, defaultOptions)
    if (data.user && setUser) {
      setUser(data.user)
    }

    return data
  }, [CMS_URL, defaultOptions, setCookie, setUser])

  return { registerUser, }
}