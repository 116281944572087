import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { NotFoundView } from 'shared/views'
import UnderConstruction from 'views/UnderConstruction'

// Vendors
import GoogleFeatures from 'vendors/Google/Routes'

/**
 * @Todo explore if there needs to be an error boundary right around the open routes \
 * (problem: currently not catching auth errors)
 */
export default function OpenRoutes(): JSX.Element {
  return (
    <Switch>

      {/* Vendor Integrations */}
      <Route path="/vendor/google">
        <GoogleFeatures />
      </Route>

      <Route path="/not-found">
        <NotFoundView />
      </Route>

      <Route path="/working-on-it">
        <UnderConstruction />
      </Route>

      <Route exact path="/">
        <Redirect to="/member/welcome" />
      </Route>

      {/* Catch all */}
      <Route>
        <Redirect to="/not-found" />
      </Route>
    </Switch>
  )
}
