import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Profile from 'features/Profile'
import SecureAccessProvider from 'components/SecureAccessProvider'

const SecureRoutes = (): JSX.Element => {
  return (
    <SecureAccessProvider>
      <Router>
        <Switch>
          <Route path="/secure/member/profile">
            <Profile />
          </Route>
          <Route exact path="/secure">
            <Redirect to="/secure/member/profile" />
          </Route>
        </Switch>
      </Router>
    </SecureAccessProvider>
  )
}

export default SecureRoutes
