import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import WelcomeView from 'views/Welcome'
import JoinView from 'vendors/Strapi/views/Join'
import LoginView from 'vendors/Strapi/views/Login'
import AuthProvider from 'shared/components/AuthProvider'

export default function SecurableRoutes(): JSX.Element {
  return (
    <AuthProvider>
      <Switch>
        <Route exact path="/member/join">
          <JoinView />
        </Route>
        <Route path="/member/login">
          <LoginView />
        </Route>
      </Switch>
      <Router basename="/member">
        <Route path="/welcome">
          <WelcomeView />
        </Route>
      </Router>
    </AuthProvider>
  )
}
