import React from 'react'
import { CookiesProvider } from 'react-cookie'
import isEmpty from 'lodash/isEmpty'
import { Contexts } from 'src/libs'
import { useAppCookies, useUserInfo } from 'src/hooks'

type Props = React.PropsWithChildren<Record<string, unknown>>

export const AuthContext = Contexts.Auth

export default function AuthProvider({ children, }: Props): JSX.Element {
  const { cookies, } = useAppCookies()
  const { isLoading, setIsLoading, user, setUser, } = useUserInfo()

  const isAuthenticated = !isEmpty(user) && !!cookies.jwt

  React.useEffect(() => {
    if(isAuthenticated) {
      if(setUser) setUser(user)
    }
  }, [isAuthenticated, setUser, user])


  return (
    <CookiesProvider>
      <Contexts.Auth.Provider value={{ isLoading, setIsLoading, user, setUser, }}>
        {children}
      </Contexts.Auth.Provider>
    </CookiesProvider>
  )
}
