import { AppConfig } from './interfaces'
import prodConfigs from './production'
import devConfigs from './development'

// @Todo Migrate "Rise" app to use TS for React template and allowJs
export function load(): AppConfig {
  const { hostname, protocol, } = global?.window?.location

  if (/themorningcatalyst\.com$/.test(hostname)) {
    const COOKIE_OPTIONS = {
      ...prodConfigs.COOKIE_OPTIONS,
      secure: /^https/.test(protocol),
      domain: hostname,
    }
    return {
      ...prodConfigs,
      COOKIE_OPTIONS,
    } as AppConfig
  }

  const COOKIE_OPTIONS = {
    ...devConfigs.COOKIE_OPTIONS,
    secure: /^https/.test(protocol),
    // domain: hostname, // @Todo revisit if this is preventing cookies from being set
  }

  return {
    ...devConfigs,
    COOKIE_OPTIONS,
  } as AppConfig
}
