import React from 'react'
import Grid from '@material-ui/core/Grid'
import { CenteredGridLayout } from '../../shared/layouts'
import Welcome from './Welcome'

// @important uses ngrok in dev environment (last URL: https://328693c7a776.ngrok.io)

export default function WelcomeView(): JSX.Element {
  return (
    <CenteredGridLayout>
      <Grid item>
        <Welcome />
      </Grid>
    </CenteredGridLayout>
  )
}
