import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import NotFound from './components/NotFound'
import OAuth2Redirect from './views/OAuth2Redirect'

const Routes = (): JSX.Element => (
  <Router basename="/vendor/google">
    <Switch>
      <Route exact path="/oauth2/redirect">
        <OAuth2Redirect />
      </Route>
      <Route path="/">
        <NotFound />
      </Route>
    </Switch>
  </Router>
)

export default Routes
