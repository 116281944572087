import { FC } from 'react'
import { CenteredGridLayout } from 'shared/layouts'

const NotFound: FC = () => (
  <CenteredGridLayout>
    <div>
      <h5>404</h5>
      <h1>There's nothing here...</h1>
    </div>
  </CenteredGridLayout>
)

export default NotFound
