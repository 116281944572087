import React from 'react'
import { useHistory } from 'react-router-dom'
import { useAppCookies } from 'hooks'
import { AuthContext } from '../shared/components/AuthProvider'

type Props = React.PropsWithChildren<Record<string, unknown>>

export default function SecureAccessProvider({ children, }: Props): JSX.Element {
  const { isLoading, user, } = React.useContext(AuthContext)
  const { cookies, } = useAppCookies()
  const history = useHistory()

  const { jwt, } = cookies

  const router = React.useMemo(() => history, [history])

  // @Todo fail fast in SecureAccessProvider if auth context is not found

  React.useEffect(() => {
    if(!isLoading) {
      if(!user && !jwt) {
        console.debug('Will re-direct to login...')
        // @Todo capture re-direct URL
        router.push('/member/login')
      } else {
        console.debug('will render (secure) wrapped component...')
      }
    }
  }, [isLoading, user, jwt, router])

  return (
    <>
      {children}
    </>
  )
}
