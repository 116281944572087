import { FC } from 'react'
import logo from '../../logo.svg'
import './style.css'

const UnderConstruction: FC = () => (
  <div className="App">
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <p>
        We're working on it!
      </p>
      {/* <a href="/welcome">Demo</a> */}
    </header>
  </div>
)

export default UnderConstruction
