import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { CenteredGridLayout } from 'shared/layouts'
import { Input, CellPhoneNumberInput } from 'shared/components'
import { request } from 'gaxios'
import { useAppCookies, useAppVars } from 'hooks'
import { isValidGaxiosResponseError } from 'libs'
import { isValidStrapiErrorResponse } from '../libs'
import { Validator, ValidationError, isValidationError } from '../libs/validators'
import { StrapiAuthResponse } from '../interfaces'
import { AuthContext } from 'shared/components/AuthProvider'
import { useHistory } from 'react-router-dom'

interface StrapiLoginState {
  identifier?: string
  email?: string
  password: string
}

const formSchema = Validator.object().shape({
  identifier: Validator.string().required(),
  password: Validator.string().min(8).required(),
})

export default function Login(): JSX.Element {
  const load = useAppVars()
  const { CMS_URL, } = load()
  const { isLoading, setIsLoading, user, setUser, } = React.useContext(AuthContext)
  const { cookies, setCookie, defaultOptions, } = useAppCookies()
  const [, setFormErrors] = React.useState<ValidationError | undefined | null>()
  const [form, setForm] = React.useState<Partial<StrapiLoginState>>()
  const history = useHistory()
  const { jwt, } = cookies
  const formData = React.useMemo(() => form, [form])

  const handleInputChange = ({ target, }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, } = target
    setForm({
      ...form,
      [name]: value,
    })
  }

  const validateForm = React.useCallback(async () => {
    try {
      await formSchema.validate(formData)
    } catch(err) {
      if(isValidationError(err)) {
        console.warn(err)
        setFormErrors(err)
      }
    }
  }, [formData])

  /**
   * @Todo refactor to ../hooks/useLogin (follow pattern for ../hooks/useRegistration)
   */
  const loginUser = React.useCallback(async () => {
    try {
      await validateForm()
      const { data, } = await request<StrapiAuthResponse>({
        baseURL: CMS_URL,
        url: '/auth/local',
        method: 'POST',
        data: formData,
      })
      setCookie('jwt', data.jwt, defaultOptions)
      if (data.user && setUser) {
        setUser(data.user)
        if(setIsLoading) setIsLoading(true)

        // window.location.reload()
        // // Redirect to profile view
        // history.replace('/secure/member/profile', {
        //   user: data.user,
        // })
      }
      } catch(err) {
      if(isValidGaxiosResponseError(err)) {
        if(isValidStrapiErrorResponse(err.response?.data)) {
          // Do stuff
        }
      }
    }
  }, [CMS_URL, defaultOptions, formData, setCookie, setIsLoading, setUser, validateForm])


  // @Todo refactor to ../hooks/useSkipIfAuthd and re-use in Login + Join
  React.useEffect(() => {
    if(!isLoading && user) {
      history.replace('/secure')

      // @hack Make sure the navigation is updated
      window.location.reload()
    }
  }, [history, isLoading, jwt, user])

  return (
    <CenteredGridLayout>
      <Typography variant="h3">Login goes here</Typography>
      <form noValidate>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <CellPhoneNumberInput
              name="identifier"
              form={form}
              setState={setForm} />
          </Grid>

          <Grid item>
            <Input
              type="password"
              label="Your password"
              name="password"
              autoComplete="current-password"
              validationSchema={formSchema.fields.password}
              onChange={handleInputChange} />
          </Grid>

          <Grid item>
            <Button onClick={loginUser}>Continue</Button>
            <Button href="/member/join">Join</Button>
          </Grid>
        </Grid>
      </form>
    </CenteredGridLayout>
  )
}
