import React, { FC } from 'react'
import styled from 'styled-components'

const StyledFrame = styled.iframe`  
  width: 100%;
  border: 0; 
  min-height: 100vh;
`

export interface IFrameProps {
  source: string
}

const IFrame: FC<IFrameProps> = ({ source, }) => {

    if (!source) {
      return <div>Loading...</div>
    }

    const src = source
    return (
        // basic bootstrap classes. you can change with yours.
      <div className="col-md-12">
        <div className="emdeb-responsive">
          <StyledFrame title="Privacy Notice" src={src}></StyledFrame>
        </div>
      </div>
    )
}

export default IFrame
