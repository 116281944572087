import { GridProps } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { FC } from 'react'
import styled from 'styled-components'

const StyledGrid = styled(Grid)`
  background-color: #f7f7f7;
  padding: 2rem;
`

// @Todo figure out API to allow for sizing of FocusBox in implementation
const FocusBox: FC<GridProps> = ({ children, ...props }) => (
  <StyledGrid container justify="space-evenly" direction="column" {...props}>{children}</StyledGrid>
)

export default FocusBox
