import React from 'react'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
// @Todo replace with 'hooks' once storybooking is done (haven't figure out TSConfig for SB just yet)
import { useAppVars } from '../../hooks'

export default function Welcome(): JSX.Element {
  const load = useAppVars()
  const { CMS_URL, } = load()
  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h5">this is</Typography>
          <Typography variant="h3">
            The Morning Catalyst
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item>
          <Button data-testid="button--join" href="/member/join">Join</Button>

          <Button
            href={`${CMS_URL}/connect/google`}
            rel="noopener noreferrer"
            startIcon={
              <Icon className="fab fa-google" fontSize="small" />
            }>
            Continue with Google
          </Button>

        </Grid>
      </Grid>
    </>
  )
}
