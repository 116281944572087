import React, { Dispatch, SetStateAction, useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import { StrapiAccessToken } from 'vendors/Strapi/interfaces'
import useAppCookies from './useAppCookies'

export interface AccessTokenHook {
  isProviderAuthd?: boolean
  hasCreds?: boolean
  accessToken?: StrapiAccessToken
  setAccessToken: Dispatch<SetStateAction<StrapiAccessToken | undefined>>
  decodeAccessToken: (encodedToken?: string) => StrapiAccessToken
}

function useAccessToken(): AccessTokenHook {
  const { cookies, setCookie, defaultOptions, } = useAppCookies()
  const [hasCreds, setHasCreds] = React.useState<boolean>()
  const [accessToken, setAccessToken] = React.useState<StrapiAccessToken>()
  const { encodedAccessToken, } = cookies

  const decodeAccessToken = React.useCallback((encodedToken?: string) => {
    const encodedJSONString = encodedToken ?? encodedAccessToken
    const decoded: string = atob(encodedJSONString as string)
    const json = JSON.parse(decoded) as StrapiAccessToken
    setAccessToken(json)
    return json
  }, [encodedAccessToken])

  useEffect(() => {
    if (accessToken) {
      const encodedToken = btoa(JSON.stringify(accessToken, null, '\t'))
      setCookie('encodedAccessToken', encodedToken, defaultOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  useEffect(() => {
    if (!isEmpty(encodedAccessToken)) setHasCreds(true)
  }, [encodedAccessToken, hasCreds])

  return {
    hasCreds,
    accessToken,
    setAccessToken,
    decodeAccessToken,
  }
}

export default useAccessToken