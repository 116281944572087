/* eslint-disable import/no-webpack-loader-syntax */
import Routes from './Routes'
import styled from 'styled-components'
import { LinearProgress } from '@material-ui/core'
import { BrowserRouter as Router } from 'react-router-dom'

import '!style-loader!css-loader!sass-loader!./scss/App.scss'

import ErrorBoundary from './shared/views/ErrorBoundary'
import { AuthProvider } from './shared/components'
import { NavBar } from './components'

/**
 * For reporting project progress visually (more to come)
 *
 * the "value" of the progress bar represents rough estimate of amount of work completed to launch
 */
const ProjectContextBar = styled.div`
  /* display: flex;
  flex-direction: column; */
  position: fixed;
  top: 0; left: 0;
  width: 100vw;
`

export default function App(): JSX.Element {
  return (
    <>
      <ErrorBoundary>
        <AuthProvider>
          <Router>
            <ProjectContextBar>
              <LinearProgress color="secondary" variant="determinate" value={5} />
              <NavBar />
              {/* @Todo Grid and WithAuth'd component for nav menu */}
            </ProjectContextBar>
            <Routes />
          </Router>
        </AuthProvider>
      </ErrorBoundary>
    </>
  )
}
