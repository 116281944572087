import React, { FC } from 'react'
import Grid, { GridProps } from '@material-ui/core/Grid'
import styled from 'styled-components'

interface ActionsBarProps {
  item?: boolean
}

const StyledGrid = styled(Grid)`
  padding-top: 2rem;
`

const ActionsBar: FC<ActionsBarProps & GridProps> = ({ children, ...props }): JSX.Element => (
  <StyledGrid container direction="row" justify="flex-end" {...props}>
    {children}
  </StyledGrid>
)

export default ActionsBar
