import { AppConfig } from './interfaces'
import addDays from 'date-fns/addDays'

const sharedConfigs: Partial<AppConfig> = {
  COOKIE_OPTIONS: {
    /**
     * Ensures that the cookies are available on every route
     */
    path: '/',
    expires: addDays(new Date(), 30),
    maxAge: 60 * 60 * 24 * 30,
  },
  COOKIE_NAME: 'app.themorningcatalyst',
  PUBLIC_URL: 'https://cobalt-chalice-297106.web.app',
}

export default sharedConfigs
