import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { AuthContext } from 'shared/components/AuthProvider'
import { useAppCookies } from 'hooks'
import { useHistory, useLocation } from 'react-router-dom'

export default function NavBar(): JSX.Element {
  const [eventKey, setEventKey] = React.useState<string>()
  const { isLoading, user, } = React.useContext(AuthContext)
  const { cookies, removeCookie, defaultOptions, } = useAppCookies()
  const { pathname, } = useLocation()
  const history = useHistory()
  const { jwt, } = cookies

  const isAuthenticated = React.useMemo(() => !!user, [user])
  const isVerifiedWithTwilio = React.useMemo(() => !!user?.twilioVerificationToken, [user])

  const handleSignOut = () => {
    removeCookie('jwt', defaultOptions)

    // Re-direct to default landing view
    history.replace('/')

    window.location.reload()
  }

  React.useEffect(() => {
    if(!isLoading) {
      setEventKey(new Date().toUTCString())

      if (!jwt && /^\/secure/.test(pathname)) {
        //  Do cleanup stuff
      }
    }
  }, [jwt, isLoading, pathname])

  return (
    <Grid key={eventKey} container direction="row">
      {isAuthenticated ? (
        <>
          {!isVerifiedWithTwilio && <Button>Verify with Twilio</Button>}
          <Button onClick={handleSignOut}>Sign out</Button>
        </>
      ) : (
        <>
          <Button href="/member/join">Join</Button>
          <Button href="/member/login">Sign in</Button>
        </>
      )}
    </Grid>
  )
}
