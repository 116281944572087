export default function PasswordComplexityTip(): JSX.Element {
  return (
    <span>
      Your password isn't complex enough. Try adding:
      <br />* A symbol
      <br />* Numbers; and/or
      <br />* More characters
    </span>
  )
}
