/* eslint-disable @typescript-eslint/no-explicit-any */
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2'
import { TextFieldProps } from '@material-ui/core/TextField'
import { SUPPORTED_REGIONS } from '../constants'
import React, { Dispatch, SetStateAction } from 'react'

export type CellPhoneNumberProps = TextFieldProps & PhoneInputProps & {
  onChange?: (value: string) => void
  setState?: Dispatch<SetStateAction<any>>
  form?: Record<string, any>
}

export default function CellPhoneNumberInput({
  autoFormat,
  enableAreaCodes,
  countryCodeEditable,
  country,
  inputProps,
  regions,
  onChange,
  name = 'username',
  setState,
  form = {},
  ...props
}: CellPhoneNumberProps): JSX.Element {
  const handleOnChange = (newValue: string) => {
    if(onChange) onChange(newValue)
    if(setState) {
      setState({
        ...form,
        [name]: newValue,
      })
    }
  }

  return (
    <PhoneInput
      autoFormat
      enableAreaCodes={enableAreaCodes}
      countryCodeEditable={countryCodeEditable ?? false}
      country={'us'}
      specialLabel={'Mobile Number' ?? props?.specialLabel ?? name}
      inputProps={{
        name,
        required: true,
        autoFocus: 1,
        // autoComplete: 'yes',
      }}
      regions={SUPPORTED_REGIONS}
      onChange={handleOnChange} />
  )
}