import { AppConfig } from '../interfaces'
import sharedConfigs from '../shared'

const config: Partial<AppConfig> = {
  ...sharedConfigs,
  AUTH_URL: 'https://cms.themorningcatalyst.com',
  CMS_URL: 'https://cms.themorningcatalyst.com',
}

export default config
