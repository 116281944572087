import { Switch, Route } from 'react-router-dom'
import OpenRoutes from './Open.routes'
import SecurableRoutes from './Securable.routes'
import SecureRoutes from './Secure.routes'

export default function Routes(): JSX.Element {
  return (
    <Switch>
      <Route
        path={[
          '/welcome',
          '/member'
        ]}>
        <SecurableRoutes />
      </Route>

      <Route path="/secure">
        <SecureRoutes />
      </Route>

      <Route>
        <OpenRoutes />
      </Route>
    </Switch>
  )
}
