import { PASSWORD_PATTERN, PHONE_NUMBER_PATTERN } from 'shared/patterns'
import * as Yup from 'yup'
import PasswordComplexityTip from '../components/PasswordComplexityTip'

export const Validator = Yup

export type ValidationError = Yup.ValidationError

export function isValidationError(error: ValidationError | unknown): error is ValidationError {
  return (error as ValidationError) !== undefined
}

export const phoneNumberSchema = Yup.string()
  .required('Phone number is required')
  .matches(PHONE_NUMBER_PATTERN, { message: 'Phone number is not valid', })

export const emailSchema = Yup.string().email().required()

export const passwordSchema = Yup.string()
  .matches(PASSWORD_PATTERN, { message: PasswordComplexityTip, })
  .min(8)
  .max(42)
  .required()
