import React, { MutableRefObject, forwardRef, ChangeEvent, FocusEvent } from 'react'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import * as Yup from 'yup'
import noop from 'lodash/noop'

export type ErrorCheckFunction = (
  ev: ChangeEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>
) => boolean | Promise<boolean | Yup.ValidationError>

type InputProps = TextFieldProps & {
  hasError?: ErrorCheckFunction
  validationSchema?: Yup.AnySchema
}

function isValidErrorCheck(errorCheckFn: ErrorCheckFunction | unknown): errorCheckFn is ErrorCheckFunction {
  return (errorCheckFn as ErrorCheckFunction) !== undefined
}

function isValidationError(data: unknown | Yup.ValidationError): data is Yup.ValidationError {
  return (data as Yup.ValidationError) !== undefined
}

function isValidRef(ref: MutableRefObject<HTMLInputElement> | unknown): ref is MutableRefObject<HTMLInputElement> {
  return (ref as MutableRefObject<HTMLInputElement>) !== undefined
}

export default forwardRef(({
  hasError,
  onBlur = noop,
  onFocus = noop,
  validationSchema,
  ...props
}: InputProps, ref) => {
  const [error, setError] = React.useState<boolean | Yup.ValidationError>()
  const [inputRef] = React.useState(() => !!ref && isValidRef(ref) ? ref : React.createRef<HTMLInputElement>())

  const currentSchema = React.useMemo(() => validationSchema, [validationSchema])

  const validateInput = React.useCallback(async () => {
    if(!currentSchema) return true

    try {
      if(inputRef && inputRef.current?.value) {
        await currentSchema.validate(inputRef.current?.value, { abortEarly: true, })
      }
    } catch(err) {
      if(isValidationError(err)) setError(err)
    }
  }, [currentSchema, inputRef])

  const syncErrorState = React.useCallback(async () => {
    if(!!currentSchema && inputRef) {
      const result = await currentSchema.isValid(inputRef.current?.value)
      if (result) setError(false)
    }
  }, [currentSchema, inputRef])

  const onBlurHandler = async(event: FocusEvent<HTMLInputElement>) => {
    event.persist()
    onBlur(event)
    if(isValidErrorCheck(hasError)) {
      const value = await hasError(event)
      setError(value)
    } else if (currentSchema) {
      await validateInput()
    }
  }

  const onFocusHandler = (event: FocusEvent<HTMLInputElement>) => {
    event.persist()
    onFocus(event)
    setError(false)
  }

  React.useEffect(() => {
    void syncErrorState()
  }, [error, syncErrorState])

  return (
    <TextField
      inputRef={inputRef}
      helperText={isValidationError(error) ? error.message : undefined}
      error={!!error}
      variant="outlined"
      autoComplete="off"
      fullWidth
      {...props}
      onFocus={onFocusHandler}
      onBlur={onBlurHandler} />
  )
})
