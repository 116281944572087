import { StrapiUser } from 'vendors/Strapi/interfaces'
import React, { Dispatch, SetStateAction } from 'react'
import { request } from 'gaxios'
import useAppVars from './useAppVars'
import useAccessTokens from './useAccessTokens'
import useAppCookies from './useAppCookies'
import isEmpty from 'lodash/isEmpty'
// import { Contexts } from 'libs'
import { SetUserStateAction, UserInfoOrNothing } from 'shared/interfaces'

export type GetUserInfoCallback = (
  accessToken?: string, userId?: string | number
) => Promise<UserInfoOrNothing>

// @Todo revise this to be more specific about attributes for user details
export interface UserInfoHook {
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  jwt?: string
  user?: UserInfoOrNothing
  setUser: Dispatch<SetUserStateAction> | undefined
  accessToken?: string
  getUserInfo: GetUserInfoCallback
}

function useUserInfo(): UserInfoHook {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [user, setUser] = React.useState<UserInfoOrNothing>()
  const load = useAppVars()
  const { cookies, } = useAppCookies()
  const { jwt, } = cookies
  const { hasCreds, decodeAccessToken, } = useAccessTokens()
  const { CMS_URL, } = load()

  // @Todo Figure out best OAuth2 flow for auth verification of secured components
  const getUserInfo = React.useCallback(
    async () => {
      if (!setUser) return null
      // @Todo support impersonation with the passed userId if the current user is an admin

      // @Todo setup Error Boundary at the vendor level
      // const { data, } = await request({
      //   url: `${CMS_URL}/token/decrypt`,
      //   method: 'POST',
      //   // headers: {
      //   //   Authorization: `Bearer ${accessToken}`,
      //   // },
      //   body: {
      //     token: jwt as string,
      //   },
      // })
      if (isEmpty(jwt)) throw new Error('JWT token is required by /auth/userinfo')

      try {
        const { data, } = await request<Partial<StrapiUser>>({
          url: `${CMS_URL}/auth/userinfo`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${jwt as string}`,
          },
        })
        console.debug({ data, })
        if (setUser) setUser(data)
        setIsLoading(false)
        return data
      } catch (err) {
        console.error(err)
        // @Todo return error
      }
      setIsLoading(false)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return null
    }, [CMS_URL, jwt, setUser])

  /**
   * @important useEffect can return a cleanup function! Did not know that
   */
  React.useEffect(() => {
    if (hasCreds) decodeAccessToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCreds])

  React.useEffect(() => {
    if (jwt) {
      void getUserInfo()
    } else {
      setIsLoading(false)
    }
  }, [getUserInfo, jwt])

  return {
    isLoading,
    setIsLoading,
    jwt: jwt as string,
    user,
    setUser,
    getUserInfo,
  }
}

export default useUserInfo
