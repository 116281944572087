import React from 'react'
import Grid, { GridProps } from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import styled from 'styled-components'

const StyledGrid = styled(Grid)`
  min-height: 100vh;
`

export default function FullLengthGridLayout({ children, }: GridProps): JSX.Element {
  return (
    <Container maxWidth="sm">
      <StyledGrid container alignItems="center" justify="flex-start" direction="column">
        {children}
      </StyledGrid>
    </Container>
  )
}
