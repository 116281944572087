import { AppConfig } from '../interfaces'
import sharedConfigs from '../shared'

const config: Partial<AppConfig> = {
  ...sharedConfigs,
  CMS_URL: 'https://cms2.themorningcatalyst',
  AUTH_URL: 'https://themorningcatalyst.ngrok.io',
}

export default config
